
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ITableStatus } from "@/types";
import { translateOffersStatus, translatePrivateOffers, translateReadyForErir } from "@/utils/translate";
import { Offer } from "@/services/offers/Offer";
import { eventBus } from "@/eventbus";
import SvgChart from "@/assets/icons/chart.svg";
import { namespace } from "vuex-class";
import TooltipCountryList from "@/components/base/TooltipCountryList.vue";
import SvgSandClock from "@/assets/icons/sand-clock.svg";
import { ITag } from "@/api/types/offers";
import SvgKey from "@/assets/icons/key.svg";
import BaseTooltip from "@/components_v3/base/formComponents/BaseTooltip.vue";
import { ITableHeader } from "@/utils";
import SvgPlus from "@/assets/icons/plus.svg";
import CreateTaskModal from "@/components_v3/tasks/CreateTaskModal.vue";
import BaseButtonV2 from "@/components_v3/base/formComponents/buttons/BaseButtonV2.vue";
import FooterBulkActions from "@/components_v3/base/table/FooterBulkActions.vue";

const offerStore = namespace('offersModule');

@Component({
    components: {
        FooterBulkActions,
        BaseButtonV2,
        CreateTaskModal,
        TooltipCountryList,
        AppTableDesign,
        SvgChart,
        SvgKey,
        SvgPlus,
        BaseTooltip,
    },
})

export default class OffersTable extends Vue {
    @Prop({ required: true }) items!: Offer[];
    @Prop({ default: 50 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;
    @offerStore.State('statPeriodForList') statPeriod;

    selected: Offer[] = [];

    headers: ITableHeader[] = [
        {
            section: 'Оффер',
            text: 'ID',
            sortable: false,
            value: 'legacy_id',
            align: 'center',
            width: '50',
            customSort: 'legacy_id',
        },
        { section: 'Оффер', text: 'Лого', sortable: false, value: 'logo', align: 'center', width: '45' },
        { section: 'Оффер', text: 'Название оффера', sortable: false, value: 'name', width: '250', customSort: 'name' },
        { section: 'Оффер', text: 'Рекламодатель', sortable: false, value: 'advertiser.name', width: '180' },
        { section: 'Оффер', text: 'Выплата', sortable: false, value: 'goals_payout', width: '130' },
        { section: 'Оффер', text: 'Доход', sortable: false, value: 'goals_revenue', width: '130' },
        { section: 'Оффер', text: 'Страны', sortable: false, value: 'countries' },
        { section: 'Оффер', text: 'Менеджер', sortable: false, value: 'manager.name' },
        { section: 'Оффер', text: 'Статус', sortable: false, value: 'status', align: 'center' },
        { section: 'Оффер', text: 'Приват', sortable: false, value: 'private', align: 'center' },
        { section: 'Оффер', text: 'Маркир', sortable: false, value: 'ready_for_erir', align: 'center' },
        {
            section: 'Оффер',
            text: 'Треб. согл.',
            sortable: false,
            value: 'confirmations.requires_advertiser_approval',
            align: 'center',
        },
        { section: 'Оффер', text: 'Конкурс', sortable: false, value: 'contests', width: '200' },
        { section: 'Оффер', text: 'Акция', sortable: false, value: 'campaigns', width: '200' },
        { section: 'Оффер', text: 'Теги', sortable: false, value: 'tags', width: '120' },
        { section: 'Оффер', text: 'Preview URL', sortable: false, value: 'preview_url', width: '200' },
        { section: 'Оффер', text: 'Срок действия', sortable: false, value: 'expiration_date', width: '120' },
        { section: 'Категории', text: 'Тип трафика', sortable: false, value: 'traffics' },
        { section: 'Категории', text: 'Направление', sortable: false, value: 'departments' },
        { section: 'Категории', text: 'Тип продукта', sortable: false, value: 'products', width: '120' },
        { section: 'Категории', text: 'Целевое действие', sortable: false, value: 'target_actions' },
        { section: 'Категории', text: 'Выплата', sortable: false, value: 'payouts', width: '180' },
        { section: 'Категории', text: 'Вертикаль', sortable: false, value: 'verticals', width: '120' },
        { section: 'Метрики', text: 'Chart', sortable: false, value: 'chart', align: 'center', fixed: true },
        { section: 'Метрики', text: 'CRt', sortable: false, value: 'metrics.cr_t', align: 'center', customSort: 'cr_t' },
        { section: 'Метрики', text: 'CRu', sortable: false, value: 'metrics.cr_u', align: 'center', customSort: 'cr_u' },
        { section: 'Метрики', text: 'AR', sortable: false, value: 'metrics.ar', align: 'center', customSort: 'ar' },
        {
            section: 'Метрики',
            text: 'EPCt',
            sortable: false,
            value: 'metrics.epc_t',
            align: 'center',
            customSort: 'epc_t',
        },
        {
            section: 'Метрики',
            text: 'EPCu',
            sortable: false,
            value: 'metrics.epc_u',
            align: 'center',
            customSort: 'epc_u',
        },
        { section: 'Метрики', text: 'EPL', sortable: false, value: 'metrics.epl', align: 'center', customSort: 'epl' },
    ];

    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.legacy_id',
            routerName: 'offer',
            text: 'legacy_id',
            icon: SvgSandClock,
            showIcon: (item: Offer): boolean => {
                return this.isExpired(item);
            },
            tooltip: (item: Offer): string => {
                return this.isExpired(item) ? 'Истёк' : '';
            },
            tooltipClass: 'base-tooltip',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.name',
            routerName: 'offer',
            text: 'name',
            tooltipClass: 'base-tooltip',
            tooltip: (item: Offer): string => item.name.length > 60 ? item.name : '',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.advertiser.name',
            routerName: 'advertiser',
            text: 'name',
            sup: 'advertiser',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateOffersStatus,
        },
        {
            slot: 'item.private',
            key: 'private',
            translate: translatePrivateOffers,
        },
        {
            slot: 'item.ready_for_erir',
            key: 'ready_for_erir',
            translate: translateReadyForErir,
        },
    ];

    dropDownItems = [
        {
            slot: 'item.tags',
            text: 'tags',
            readonly: true,
            template: (i: ITag): string => i.name,
        },
    ];

    timeData = [
        {
            slot: 'item.expiration_date',
            key: 'expiration_date',
            isWithTime: false,
        },
    ];

    uniqueData = [
        { slot: 'item.logo' },
        { slot: 'item.countries' },
        { slot: 'item.chart' },
        { slot: 'item.preview_url' },
        {
            slot: 'item.confirmations.requires_advertiser_approval',
            template: (i: Offer): string => i.confirmations?.requires_advertiser_approval! ? 'Да' : 'Нет',
        },
        {
            slot: 'item.traffics',
            template: (i: Offer): string => i.getCategoryValues('traffics'),
        },
        {
            slot: 'item.departments',
            template: (i: Offer): string => i.getCategoryValues('departments'),
        },
        {
            slot: 'item.products',
            template: (i: Offer): string => i.getCategoryValues('products'),
        },
        {
            slot: 'item.target_actions',
            template: (i: Offer): string => i.getCategoryValues('target_actions'),
        },
        {
            slot: 'item.payouts',
            template: (i: Offer): string => i.getCategoryValues('payouts'),
        },
        {
            slot: 'item.verticals',
            template: (i: Offer): string => i.getCategoryValues('verticals'),
        },
        {
            slot: 'item.contests',
            template: (i: Offer): string => i.getCategoryValues('contests'),
        },
        {
            slot: 'item.campaigns',
            template: (i: Offer): string => i.getCategoryValues('campaigns'),
        },
        {
            slot: 'item.goals_payout',
            template: (i: Offer): string => i.getPayoutAndRevenueValue.payout,
        },
        {
            slot: 'item.goals_revenue',
            template: (i: Offer): string => i.getPayoutAndRevenueValue.revenue,
        },
        {
            slot: 'item.manager.name',
        },
    ];

    isShowCreateTasksModal = false;

    footerBulkActions = [
        { text: 'Создать доступы', icon: SvgKey, action: this.invokeMultiplyApprovalModal },
        { text: 'Создать задачи', icon: SvgPlus, action: this.openTasksModal },
    ];

    get getSelectIds(): number[] {
        return this.selected.map(i => i.legacy_id) as number[];
    }

    created(): void {
        eventBus.$on('clear-selected-offers', () => {
            this.selected = [];
        });
    }

    mounted(): void {
        this.headers.forEach((head) => {
            if (head.value.includes('metrics')) {
                head.customSort = `days${this.statPeriod}_${head.customSort}`;
            }
        });
    }

    get isShowDesktopActions(): boolean {
        return this.$vuetify.breakpoint.width > 890;
    }

    invokeMultiplyApprovalModal(): void {
        const selectedItems = JSON.parse(JSON.stringify(this.selected));
        const listIds = selectedItems.map(item => item.legacy_id);
        eventBus.$emit('open-multiply-approval-modal', listIds);
    }

    openTasksModal(): void {
        this.isShowCreateTasksModal = true;
    }

    resetSelected(): void {
        this.selected = [];
    }

    openChart(offer: Offer): void {
        const offerData = {
            id: offer.legacy_id,
            name: offer.name,
            metrics: offer.metrics,
            isAPI: offer.traffics.some(t => t.id === 1),
        };
        eventBus.$emit('show-offer-statistic', offerData);
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }

    isExpired(item: Offer): boolean {
        return new Date(item.expiration_date!)! < new Date();
    }

    getAddRowClass(item: Offer): string {
        const isExpired = this.isExpired(item);
        return isExpired ? 'row-is-expired' : '';
    }

    @Watch('statPeriod')
    updateMetrics(): void {
        this.items.forEach(i => i.setMetrics());
    }
}

